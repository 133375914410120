// Function based off excels pmt function
const pmt = (rate, nper, pv, fv, type) => {
  if (!fv) fv = 0;
  if (!type) type = 0;
  if (rate == 0) return - (pv + fv) / nper;
  const pvif = Math.pow(1 + rate, nper);
  let payment = rate / (pvif - 1) * -(pv * pvif + fv);
  if (type == 1) {
    payment /= (1 + rate);
  };
  return payment;
}
export default pmt