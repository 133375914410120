import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import { Button, makeStyles } from '@material-ui/core';
import { get } from 'lodash';
import Text from '../../../components/typography'
import { theme } from '../../../themes/default-theme';
import { Cta } from '../../../components/ui';
import searchIcon from '../../../assets/icons/model-postcode-search.svg';
import Modal from '../../../components/ui/Modal';
import axios from 'axios';
import latestTimestamp from '../../../../src/util/latestPostcodeTimestamp';

import getClosestCoodinate from '../../../../src/util/getClosestCoodinate';

const FormHeader = styled.div`
h3 {
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
    color: #2E3238;
}
> div {
    font-size: 16px;
    line-height: 23px;
    color: #758094;
}
${theme.breakpoints.up('md')} {
    margin-bottom: 25px;
}
${theme.breakpoints.down('sm')} {
    margin-bottom: 20px;
}
`

const Input = styled.div`
  margin: 10px 0;
  text-align: left;
  + h6 {
    margin: 20px 0;
    font-size: 15px;
    line-height: 21px;
    color: #010101;
  }
  > p {
    font-size: small;
  }
  ${theme.breakpoints.up('md')} {
    margin: 12px 0;
  }

  ${theme.breakpoints.up('lg')} {
    margin: 15px 0;
  }
`

const Or = styled(Text)`

`

const PostCodeInput = styled(TextField)`
  width: 100%;
  input {
    padding-right: 45px;
  }
`

const SetLocationWrapper = styled.span`
  ${props => !props.isEnabled && `
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  `}
`

const SetLocation = styled(Cta)`
  min-width: 250px;
  font-weight: 700;
  font-size: 15px;
  line-height: 21px;
  color: #0170D4;
  padding: 13px 30px;
  text-transform: uppercase;
`

const Error = styled.p`
  // position: absolute;
  margin: 5px;
`

const PostCodeInputContainer = styled.div`
  position: relative;
`

const SubmitIcon = styled.img`
  width: 20px;
  height: 20px;
  display: block;

  ${theme.breakpoints.down('sm')} {
    width: 12px;
    height: 12px;
  }
`
const Submit = styled(Button)`
  border-radius: 30px;
  min-width: auto;
  color: ${theme.palette.white};
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);

  ${theme.breakpoints.down('sm')} {
    width: 26px;
    height: 26px;
    right: 10px;
  }
`

const Continue = styled.div`
    margin-top: 25px;
    padding-top: 25px;
    border-top: 1px solid ${theme.palette.middleGrey};
    button {
        max-width: 200px;
        float: right;
        font-size: 15px;
        line-height: 21px;
        font-weight: 700;
    }
`

const PostcodePopup = (props) => {

    const [open, setOpen] = useState(props.open || false);
    const [postcodeError, setPostcodeError] = useState(false);
    const [postcode, setPostcode] = useState('');
    const [locationBtnActive, setLocationBtn] = useState(true);
    const [postcodes, setPostcodes] = useState([]);
    const [gettingLocation, isGettingLocation] = useState(false);

    useEffect(() => {
        const getPostcodes = () => {
          //setPostcodeLoading(true);
          axios.get('/data/postcodes.json', {})
          .then(function ( response) {
            // handle success
            const lsPostcodes = {
              date: Date.now(),
              data: response.data
            }
            setPostcodes(lsPostcodes.data);
            localStorage.setItem('postcodes', JSON.stringify(lsPostcodes))
            //setPostcodeLoading(false);
          })
          .catch(function (error) {
            // handle error
            console.log(error);
            //setPostcodeLoading(false);
          })
          .then(function () {
            // always executed
            //setPostcodeLoading(false);
          });
        }
    
        if (localStorage && localStorage.getItem('postcodes')) {
          const lsPostcodes = JSON.parse(localStorage.getItem('postcodes'));
          if (lsPostcodes.data && lsPostcodes.date > latestTimestamp) {
            setPostcodes(lsPostcodes.data);
          } else {
            getPostcodes();
          }
        } else {
          getPostcodes();
        }
      }, [])  

    const useStyles = makeStyles(theme => ({
        modalOverlay: {
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 0,
                paddingRight: 0,
            }
        },
        content: {
            position: 'relative',
            zIndex: 1001,
            display: 'inline-block',
            verticalAlign: 'middle',
            width: '100%',
            maxWidth: '544px',
            margin: 'auto',
            background: '#F3F4F7',
            [theme.breakpoints.up('md')]: {
                padding: '35px 45px'
            },
            [theme.breakpoints.down('sm')]: {
                padding: '20px'
            }
        },
        modalInner: {
            width: '100%',
            display: 'inline-block',
            maxWidth: 1254,
        },
        modalClose: {
            top: '20px',
            right: '20px',
            position: 'fixed',

        }
    }));
    

    const handlePostcodeChange = (e) => {
        const val = e.target.value;
        if (!isNaN(val) && val.length <= 4) {
            setPostcode(val);
        }
    }

    const handlePostcodeKeyup = (e) => {
        setPostcodeError(false);

        if (postcode.length === 4 && e.keyCode == 13) {
            handleSubmit('postcode');
            return
        }
        if (e.keyCode === 13) {
            setPostcodeError(true)
        }  else {
            setPostcode(e.target.value);
        }
    }
    
    const handleSubmit = (type) => {
        props.onSubmit(postcode);
        if (type === 'postcode') {
            const userLocation = window.localStorage.userLocation ? JSON.parse(window.localStorage.userLocation) : {}
            const data = {
                ...userLocation,
                postcode
            }
            localStorage.setItem('userLocation', JSON.stringify(data));
        }
    }

    const getCurrentLocation = () => {

        if (navigator.geolocation) {
          isGettingLocation(true);
          navigator.geolocation.getCurrentPosition(showPosition, () => {
            setLocationBtn(false);
            isGettingLocation(false);
          });
        } else {
          setLocationBtn(false);
          setState(false);
        }
    }

    const showPosition = (position) => {
        const localStorage = window.localStorage;
        const location = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        }
        const userLocation = window.localStorage.userLocation ? JSON.parse(window.localStorage.userLocation) : {}

        isGettingLocation(false);
        setLocationBtn(true);

        console.log('location', location, postcodes);
        const closest = getClosestCoodinate(location, postcodes);
        console.log('closest location', closest);
        if (closest && closest.item && closest.item.postcode) {
            setPostcode(closest.item.postcode)
            const data = {
                ...userLocation,
                ...location,
                postcode: closest.item.postcode
            }
            localStorage.setItem('userLocation', JSON.stringify(data));
        } else {
            const data = {
                ...userLocation,
                ...location
            }
            localStorage.setItem('userLocation', JSON.stringify(data));
        }
        // onSubmit(data, 'location')
    }

    const classes = useStyles();

    return (

        <Modal
            isOpen={open}
            onClose={() => {
                setOpen(false);
                props.onClose()
            }}
            overlayClassname={classes.modalOverlay}
            innerClassname={classes.modalInner}
            closeClassname={classes.modalClose}
            contentClassname={classes.content}
            backdropBackground={'rgba(46, 50, 56, 0.8)'}
            iconVividBlue={true}
        >
            <FormHeader>
                <Text variant="h3" uppercase align="center">Enter Your location</Text>
                <Text variant="body2" align="center">Please enter your postcode so we can provide you with location specific pricing and information</Text>
            </FormHeader>
            <Input>
                <PostCodeInputContainer>
                    <PostCodeInput
                        placeholder={'Postcode'}
                        value={postcode}
                        onChange={(e) => handlePostcodeChange(e)}
                        onKeyUp={handlePostcodeKeyup}
                        error={postcodeError}
                        variant="outlined"
                        type="tel"
                    />
                    <Submit onClick={() => handleSubmit('postcode')}>
                        <SubmitIcon src={searchIcon} alt="search" />
                    </Submit>
                </PostCodeInputContainer>
            </Input>
            <>
                <Or variant="subtitle1" uppercase align="center">Or</Or>
                <Input>
                    <SetLocationWrapper
                        isEnabled={locationBtnActive}
                    >
                        <SetLocation
                            onClick={getCurrentLocation}
                            outline>{gettingLocation ? 'Getting location...' : 'Use my location'}</SetLocation>
                    </SetLocationWrapper>
                    {!locationBtnActive &&
                        <Error>Browser location is blocked.<br />Please update your browser settings.</Error>
                    }
                </Input>
                <Continue>
                    <Cta primary uppercase onClick={handleSubmit}>
                        Continue
                    </Cta>
                </Continue>
            </> 
        </Modal>

    )
}

PostcodePopup.propTypes = {

}

PostcodePopup.defaultProps = {
    onSubmit: () => {},
    onClose: () => {}
}

export default PostcodePopup